body {
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif; }

.c-form {
  background-color: #fff;
  width: 100%;
  border-radius: 2px; }

.c-form__content-top {
  background-color: #0F1A34;
  padding: 30px 15px; }

.c-form__title {
  text-align: center;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 20px;
  line-height: 1.2; }

.c-form__content {
  padding: 20px 20px 0px 20px; }

.c-form__item {
  width: 100%;
  margin-bottom: 20px; }

.c-form__label {
  width: 100%;
  position: relative; }

.c-form__input-title {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 300;
  color: #333; }

.input__tel,
.input__text {
  width: 100%;
  padding: 10px;
  background: #F6F6F6;
  border: 1px solid #F6F6F6;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px; }

.input__tel.\--error,
.input__text.\--error {
  border-color: #f99; }

.c-form__input-error {
  position: absolute;
  bottom: -22px;
  right: 0;
  line-height: 1;
  font-size: 12px;
  color: #f99; }

.c-form__checkbox {
  display: flex;
  align-items: center;
  color: #333;
  font-size: 12px; }

.c-form__checkbox input[type=checkbox] {
  margin-right: 4px; }

.c-form__btn {
  background: linear-gradient(180deg, #EA262E 0%, #96151A 100%);
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  color: #FFF;
  padding: 10px;
  border: none;
  width: 100%; }

.c-form__conf {
  padding: 5px 20px 20px 20px;
  display: flex;
  align-items: center;
  color: #333;
  font-size: 10px; }

.c-form__conf--icon {
  margin-right: 5px; }

.c-modal-form {
  padding: 0px; }

.c-modal-form .c-form {
  max-width: 430px; }

.c-modal-form .fancybox-button svg {
  color: #FFF; }

.c-modal {
  position: relative;
  z-index: 1000000; }

.c-modal__container {
  position: fixed;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: 0;
  background: #0000005a;
  top: 0;
  touch-action: manipulation;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  width: 100%; }

.c-modal__content {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 55rem;
  height: 100%;
  margin: auto;
  padding: 0 2rem; }

.c-message__item {
  padding: 40px 80px 40px 40px;
  display: flex;
  border-radius: 2px;
  background-color: #fff;
  position: relative; }

.c-message__item--close {
  position: absolute;
  right: 0;
  top: 0;
  width: 3.2rem;
  height: 3.2rem;
  opacity: .3;
  cursor: pointer; }

.c-message__wrap-img {
  margin-right: 35px;
  max-width: 120px; }

.c-message__img {
  min-width: 100px; }

.c-message__title {
  font-weight: 400;
  line-height: 25px;
  font-size: 16px;
  color: #515255; }

.c-message__text {
  margin-top: 10px;
  font-weight: 400;
  line-height: 15px;
  font-size: 12px;
  color: #949699; }

.c-message__msg {
  margin-top: 10px; }

/*

.c-form {
    max-width: 430px;
    width: 100%;
    color: #fafafa;
    // box-shadow: 0 2px 4px rgb(0 0 0 / 24%), 0 4px 16px rgb(0 0 0 / 16%);
    border-radius: 2px;
    text-align: left;
}

.c-form.\--white {
    background-color: #fff;
    color: #222;
}


.c-form__title {
    text-align: center;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 20px;
    line-height: 1.2;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;
    padding: 25px 10px;
    background-color: $accent-color
}



@media (max-width: 1024px) {
    .c-form__title{
        font-size: 18px;
    }
}

.c-form__descr {
    margin-top: 30px;
    color: #bcbfc4;
}

.c-form__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 30px;
}

.c-form__item:not(:first-child){
    margin-top: 20px;
}

.c-form__label {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    line-height: 25px;
    font-size: 1rem;
    color: #2e3033;
    position: relative;
    margin-bottom: 0;
}

.c-form__input {
    padding: 8px 10px;
    margin-top: 5px;
    border: 1px solid #e1e2e6;
    background-color: #f2f3f5;
    border-radius: 2px;
    // box-shadow: inset 1px 1px 3px rgb(0 0 0 / 10%);
}

.c-form__input-error{
    position: absolute;
    bottom: -23px;
    right: 0;
    line-height: 1.2;
    font-size: 12px;
    color: #f99;
}

.c-form__content--nonpad .form_button {
    padding: 0 30px;
}

.c-form__btn {
    width: 100%;
    color: #fff;
    font-weight: 400;
    line-height: 1rem;
    font-size: 1rem;
    text-align: center;
    padding: 10px 5px;
    text-decoration: none;
    transition: all .2s;
    cursor: pointer;
    border: 1px solid #96151A;
    background: linear-gradient(180deg, #EA262E 0%, #96151A 100%);
    border-radius: 5px;
}

.c-form__btn:hover,
.c-form__btn:focus{
    background-color: transparent;
    color: $accent-color;
}

.c-form__conf {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    line-height: normal;
    font-size: 10px;
    color: #bcbfc4;
}

.section-promo__wrap-form {
    position: relative;
    z-index: 98;
    display: flex;
    justify-content: center;
}

#modal-callback.fancybox-content, 
#modal-callback .c-form__content
{
    padding: 0px;
}
.c-modal-form .c-form__label{
    padding: 20px;
    padding-bottom: 0px;
}


.c-modal-form .form_button{
    padding: 20px
}
.c-modal-form .c-form__conf{
    margin-top: 0px;
    padding-bottom: 20px;
    
}
// #modal-callback .c-form__item{
//     padding: 20px 0px;
// }




// @media (max-width: 992px) {

//     .section-promo .section-promo__wrap-form{
//         display: none;
//     }

// }

*/
.header-mobile {
  padding: 10px 20px;
  box-shadow: 0 0 20px 0 #bcbfc4;
  background-color: #FFF; }

.block-fixed {
  position: relative;
  right: 0;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 400; }

.header-mobile__contact-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; }

.header-mobile__burger-menu {
  cursor: pointer;
  position: relative;
  width: 35px;
  height: 25px; }

.header-mobile__burger--global {
  backface-visibility: hidden;
  position: absolute;
  left: 0;
  border-top: 2px solid #96151A;
  width: 100%;
  transition: 0.55s; }

.header-mobile__burger-top {
  top: 0; }

.header-mobile__burger-middle {
  top: 50%;
  transform: translateY(-50%); }

.header-mobile__burger-bottom {
  bottom: 0px; }

.header-mobile__burger-menu.active .header-mobile__burger--global {
  backface-visibility: hidden;
  transition: 0.2s 0.2s; }

.header-mobile__burger-menu.active .header-mobile__burger-top {
  top: 15px;
  transform: rotate(50deg); }

.header-mobile__burger-menu.active .header-mobile__burger-bottom {
  top: 15px;
  bottom: auto;
  transform: rotate(-410deg); }

.header-mobile__burger-menu.active .header-mobile__burger-middle {
  opacity: 0; }

.header-mobile__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }

.header-mobile__logo {
  flex: 1;
  max-width: 30%; }

.header-mobile__inner-contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100px; }

.header-mobile__inner-phone {
  flex-basis: 30px;
  position: relative; }

.header-mobile__phone {
  display: inline-block;
  font-size: 1px;
  font-weight: 500;
  line-height: 1;
  vertical-align: middle;
  opacity: 0; }

.header-mobile__inner-phone svg {
  width: 30px;
  height: 30px; }

.header-mobile__mail {
  flex-basis: 30px; }

.header-mobile__mail svg {
  width: 30px;
  height: 30px; }

.header-mobile__link-img {
  width: 2.2rem; }

.header-mobile__list {
  list-style-type: none;
  margin: 2rem 0 0; }

.header-mobile__list-item:not(:first-child) {
  margin-top: 1rem; }

.header-mobile__link {
  text-decoration: none;
  color: #2e3033; }

.header-mobile__link:active,
.header-mobile__link:focus,
.header-mobile__link:hover {
  text-decoration: none;
  color: #626366; }

.header-mobile__burger {
  cursor: pointer; }

.header-mobile--mobile {
  padding-top: 20px; }

.header.is-clone {
  position: fixed;
  top: -10rem;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
  transition: .5s;
  padding: 10px 0;
  background-color: #FFF;
  box-shadow: 0 4px 16px 0 rgba(154, 147, 140, 0.5); }

.header.is-fixed {
  top: 0 !important; }

.header__contact--clone {
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.header__phone--clone {
  color: #0F1A34; }

.header__phone--clone {
  text-decoration: none;
  font-weight: 400; }

.header__phone--clone {
  display: block;
  font-size: 20px;
  margin-bottom: 5px; }

.header__btn--clone {
  cursor: pointer;
  border: solid 1px #0F1A34;
  color: #0F1A34;
  background-color: transparent;
  text-align: center;
  padding: 5px 10px;
  text-decoration: none;
  transition: all .2s; }

.header__btn--clone:hover,
.header__btn--clone:focus {
  background-color: #0F1A34;
  color: #FFF;
  text-decoration: none; }

@media (max-width: 1024px) {
  .header__btn--clone {
    padding: 5px;
    font-size: 14px; } }

.header {
  padding: 10px 0px;
  background-color: #FFF; }

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.header__wraper-logo {
  flex-basis: 15%;
  width: 15%; }

.header__menu {
  flex-basis: 50%;
  width: 50%; }

.header__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
  padding: 0px;
  list-style: none; }

.header__link {
  font-size: 14px; }

.header__link:hover,
.header__link:focus {
  color: #AD0600;
  text-decoration: none; }

.header-contact__wrapper {
  flex-basis: 34%;
  width: 34%;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.header__contact {
  text-align: right;
  width: 50%;
  margin-right: 10px; }

.header__phone {
  font-weight: 800; }

.header__email {
  font-weight: 200;
  font-size: 14px;
  color: #777777; }

.header__phone:hover,
.header__phone:focus {
  color: #AD0600;
  text-decoration: none; }

.header__email:hover,
.header__email:focus {
  color: #AD0600;
  text-decoration: none; }

.header__btn {
  width: 50%; }

.header-btn__link {
  padding: 10px 15px;
  font-weight: 600;
  color: #AD0600;
  border: 1px solid #AD0600;
  border-radius: 5px; }

.header-btn__link:hover,
.header-btn__link:focus {
  background-color: #AD0600;
  color: #FFF;
  text-decoration: none; }

@media (max-width: 1024px) {
  .header__wraper-logo {
    flex-basis: 11%;
    width: 11%; }
  .header__menu {
    flex-basis: 52%;
    width: 52%; }
  .header__link {
    font-size: 13px; }
  .header-contact__wrapper {
    flex-basis: 33%;
    width: 33%; }
  .header__contact {
    font-size: 14px; }
  .header-btn__link {
    padding: 5px;
    font-size: 14px; } }

.section-promo {
  position: relative;
  background-size: cover;
  padding: 40px 0px;
  color: #fff; }

.section-promo__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between; }

.section-promo__content {
  flex-basis: 70%;
  min-height: 150px; }

.section-promo__title {
  font-size: 50px;
  font-weight: bold;
  color: #FFF;
  text-transform: uppercase;
  line-height: 1.2; }

.section-promo__desc {
  margin-top: 10px;
  display: inline-block;
  background-color: #0F1A34;
  color: #FFF;
  padding: 10px 20px;
  line-height: 1;
  font-size: 30px;
  border-radius: 5px;
  margin-bottom: 30px; }

.promo-utp__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center; }

.promo-utp__item {
  flex-basis: 30%;
  margin-right: 3.33%;
  margin-bottom: 3.33%;
  display: flex;
  align-items: center; }

.promo-utp__icon {
  background: linear-gradient(180deg, #5DA3FF 0%, #1E7FFF 100%);
  height: 55px;
  width: 55px;
  flex-basis: 55px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center; }

.promo-utp__icon svg {
  overflow: hidden;
  vertical-align: middle; }

.promo-utp__text {
  flex: 1;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px; }

.section-promo__form {
  flex-basis: 30%; }

@media (max-width: 992px) {
  .section-promo__wrapper {
    flex-wrap: wrap; }
  .section-promo__content, .section-promo__form {
    flex-basis: 100%;
    width: 100%; }
  .section-promo__wrapper .c-form {
    max-width: 100%; }
  .section-promo__wrapper .c-form__content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px 10px;
    padding-bottom: 0px; }
  .section-promo__wrapper .c-form__content .c-form__item {
    width: 32%;
    flex-basis: 32%; }
  .section-promo__wrapper .c-form__conf {
    justify-content: center; } }

@media (max-width: 576px) {
  .section-promo__title {
    font-size: 30px; }
  .section-promo__desc {
    padding: 10px;
    font-size: 20px; }
  .promo-utp__wrapper {
    justify-content: space-between; }
  .promo-utp__item {
    flex-basis: 49%;
    width: 49%;
    margin-right: 0px;
    margin-bottom: 10px; }
  .promo-utp__icon {
    width: 45px;
    height: 45px;
    flex-basis: 45px; }
  .promo-utp__text {
    font-weight: 300; }
  .section-promo__wrapper .c-form__content .c-form__item {
    width: 100%;
    flex-basis: 100%; } }

.section-advantages {
  position: relative;
  z-index: 2; }

.section-advantages::before {
  background-image: url("../img/bg-section-advantages.png");
  background-size: cover;
  height: 40%;
  width: 100%;
  content: '';
  position: absolute;
  top: 0px;
  z-index: 1; }

.section-advantages::after {
  background-image: url("../img/bg-section-advantages2.png");
  background-size: cover;
  height: 60%;
  width: 100%;
  content: '';
  position: absolute;
  bottom: 0px;
  z-index: 1; }

.section-advantages * {
  z-index: 9;
  position: relative; }

.section-advantages .section__title {
  color: #FFF; }

.section-advantages .section__text {
  font-weight: 300;
  font-size: 16px;
  line-height: 1.5;
  color: #FFFFFF; }

.advantages__wrapper {
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.advantages__item {
  flex-basis: 24%;
  margin-bottom: 50px;
  background-color: #FFF;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px; }

.advantages__item::before {
  content: '';
  height: 3px;
  width: 50px;
  background-color: #1E7FFF;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%); }

.advantages__icon {
  background: linear-gradient(180deg, #93C2FF 0%, #1E7FFF 80.89%);
  border-radius: 0px 0px 5px 5px;
  padding: 50px 0px 20px 0px;
  width: 100px;
  margin-top: -30px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center; }

.advantages__text {
  text-align: center;
  padding: 0px 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.2;
  color: #333;
  width: 100%; }

.advantages-alert {
  display: flex;
  align-content: center;
  justify-content: space-between;
  background: #001951;
  border-radius: 5px;
  padding: 40px 20px;
  margin-bottom: -130px; }

.advantages-alert__icon {
  flex-basis: 60px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center; }

.advantages-alert__text {
  flex: 1; }

.advantages-alert__text p {
  color: #FFF;
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 0px; }

.advantages-alert__text p:not(:last-child) {
  margin-bottom: 10px; }

@media (max-width: 992px) {
  .advantages__wrapper {
    justify-content: center; }
  .advantages__item {
    flex-basis: 32%;
    width: 32%;
    margin-right: 1.33%; } }

@media (max-width: 576px) {
  .advantages__wrapper {
    justify-content: space-between; }
  .advantages__item {
    flex-basis: 49%;
    width: 49%;
    margin-right: 0px; }
  .advantages__icon {
    padding: 20px 0px 20px 0px; }
  .advantages__text {
    font-size: 14px;
    padding: 0px 10px; }
  .advantages-alert {
    flex-direction: column;
    padding: 15px; }
  .advantages-alert__icon {
    margin-right: 0px; }
  .advantages-alert__text p {
    font-size: 14px;
    text-align: center; } }

.section-application {
  position: relative;
  padding-top: 130px; }

.section-application * {
  z-index: 9;
  position: relative; }

.section-application::before {
  content: '';
  position: absolute;
  bottom: 0px;
  z-index: 1;
  background-image: url("../img/bg-section-advantages.png");
  background-size: cover;
  height: 40%;
  width: 100%; }

.application__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center; }

.application__item {
  flex-basis: 49%;
  margin-bottom: 2%;
  position: relative; }

.application__link {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 10; }

.application__images {
  height: 190px; }

.application__images img {
  object-fit: cover;
  width: 100%;
  height: 100%; }

.application__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  background-color: #1E7FFF;
  padding: 0px 10px;
  margin-top: -5px;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  color: #FFFFFF; }

.application__icon {
  font-size: 33px;
  font-weight: 300; }

.application__link:hover ~ .application__content,
.application__link:focus ~ .application__content {
  background-color: #AD0600; }

@media (max-width: 576px) {
  .application__item {
    flex-basis: 100%; }
  .application__images {
    height: 120px; }
  .application__content {
    height: 45px;
    font-weight: normal;
    font-size: 14px; } }

.footer__top {
  background-color: #313B47;
  padding: 10px 0px; }

.c-footer__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.footer__logo {
  flex-basis: 200px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-start; }

.footer__menu {
  flex-basis: 60%; }

.footer__nav {
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.footer__nav-item:not(:last-child) {
  margin-right: 10px; }

.footer__nav-link {
  color: #FFF;
  font-size: 14px; }

.footer__nav-link:hover,
.footer__nav-link:focus {
  color: #96151A;
  text-decoration: none; }

.footer__contact {
  flex-basis: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-end; }

.footer__tel {
  font-size: 18px;
  font-weight: 600;
  color: #FFF; }

.footer__tel:hover,
.footer__tel:focus {
  color: #96151A;
  text-decoration: none; }

.footer__email {
  font-size: 14px;
  color: #FFF; }

.footer__email:hover,
.footer__email:focus {
  color: #96151A;
  text-decoration: none; }

.footer__bottom {
  padding: 10px 0px;
  background-color: #222222; }

.bottom__text {
  flex-basis: 70%;
  width: 70%;
  color: #FFF;
  font-size: 13px; }

.copy-wbooster {
  flex-basis: 30%;
  width: 30%;
  display: flex;
  justify-content: flex-end; }

.wbooster {
  display: flex;
  align-content: center; }

.wbooster__logo {
  flex-basis: 50px;
  margin-right: 5px; }

.wbooster__text {
  flex: 1;
  font-size: 12px;
  color: #FFF;
  display: flex;
  align-items: center; }

@media (max-width: 1024px) {
  .footer__contact {
    flex-basis: 25%;
    width: 25%; }
  .c-footer__wrapper .bottom__text {
    flex-basis: 62%; } }

@media (max-width: 992px) {
  .footer__nav {
    display: none; }
  .footer__contact {
    flex-basis: 35%; } }

@media (max-width: 576px) {
  .c-footer__wrapper {
    flex-direction: column; }
  .footer__logo {
    flex-basis: 100%;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px; }
  .footer__contact {
    flex-basis: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: space-between; }
  .footer__tel,
  .footer__email {
    font-size: 16px;
    font-weight: normal; }
  .bottom__text {
    flex-basis: 100%;
    width: 100%;
    margin-bottom: 10px; }
  .copy-wbooster {
    flex-basis: 100%;
    width: 100%;
    justify-content: center; } }

.product__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.flip-card {
  flex-basis: 32%;
  position: relative;
  margin-bottom: 20px;
  height: 240px; }

.flip-card .front {
  position: relative;
  background: linear-gradient(270deg, #00123B 0%, rgba(0, 18, 59, 0.1) 95.53%);
  background-blend-mode: overlay, normal;
  transform: matrix(-1, 0, 0, 1, 0, 0);
  height: 100%; }

.flip-card .front .inner {
  transform: matrix(-1, 0, 0, 1, 0, 0);
  height: 100%;
  position: relative; }

.flip-card .front .product__name {
  position: absolute;
  top: 20px;
  width: 70%;
  left: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #FFF; }

.flip-card .front .product__images {
  position: absolute;
  top: 60%;
  transform: translateY(-50%);
  right: 0px; }

.flip-card .front .product__btn {
  position: absolute;
  bottom: 0px;
  left: 0px;
  background-color: #1E7FFF;
  padding: 4px 10px;
  color: #FFF;
  cursor: pointer; }

.flip-card .back {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #ad0600cc; }

.flip-card .back .back-cancel {
  height: 30px;
  width: 30px;
  background-color: #FFF;
  color: #96151A;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  cursor: pointer; }

.flip-card .back .inner {
  padding: 20px;
  color: #FFF;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.flip-card .back .inner ul {
  padding-left: 20px; }

.flip-card .back .inner p {
  font-size: 22px;
  font-weight: 600; }

.flip-card .back .inner .product__link {
  background-color: #fff;
  padding: 10px 25px;
  border-radius: 5px;
  color: #AD0600;
  font-size: 18px;
  font-weight: 600;
  margin-top: auto; }

.flip-card .back .inner .product__link:hover,
.flip-card .back .inner .product__link:focus {
  opacity: .9; }

.product-bottom__content {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.product-bottom__text {
  flex-basis: 64%;
  width: 64%; }

.product-bottom__text p {
  margin-bottom: 0px; }

.product-bottom__bnt {
  flex-basis: 32%; }

.product-bottom__link {
  display: block;
  background: linear-gradient(180deg, #EA262E 0%, #96151A 100%);
  border-radius: 5px;
  color: #FFF;
  padding: 20px 0px;
  text-align: center; }

.product-bottom__link:hover,
.product-bottom__link:focus {
  color: #FFF;
  background-color: #96151A;
  text-decoration: none; }

@media (max-width: 992px) {
  .flip-card {
    flex-basis: 49%;
    width: 49%;
    height: 190px; } }

@media (max-width: 576px) {
  .flip-card {
    flex-basis: 100%;
    width: 100%;
    height: 190px; }
  .flip-card .back .inner {
    padding: 10px; }
  .flip-card .back .inner * {
    font-size: 14px; }
  .product-bottom__content {
    flex-direction: column; }
  .product-bottom__text,
  .product-bottom__bnt {
    flex-basis: 100%;
    width: 100%; }
  .product-bottom__bnt {
    margin-top: 10px; } }

.about__content {
  display: flex; }

.about__text {
  flex-basis: 50%;
  padding-right: 20px; }

.about__link {
  display: inline-block;
  background: linear-gradient(180deg, #EA262E 0%, #96151A 100%);
  border-radius: 5px;
  padding: 15px 40px;
  font-size: 18px;
  color: #FFF; }

.about__link:hover,
.about__link:focus {
  background: #96151A;
  color: #FFF;
  text-decoration: none; }

.about__img {
  flex-basis: 50%;
  position: relative; }

.about__img::before {
  content: '';
  border: 1px solid #AD0600;
  width: 80%;
  height: 80%;
  bottom: 40px;
  left: 10%;
  position: absolute; }

.about__images {
  width: 80%;
  position: relative;
  z-index: 3; }

.about__sertificate {
  display: flex;
  align-items: center;
  margin-top: -110px;
  justify-content: flex-end;
  position: relative;
  z-index: 4; }

.about__sertificate a:not(:last-child) {
  margin-right: 20px; }

.about__sertificate img {
  max-height: 200px;
  width: auto; }

.about__text .d-flex {
  flex-wrap: wrap; }

.about__text .text {
  flex-basis: 100%; }

.mobile-sertificate {
  display: none; }

@media (max-width: 1024px) {
  .about__text {
    flex-basis: 60%;
    width: 60%; }
  .about__text p {
    margin-bottom: 10px;
    font-size: 14px; }
  .about__img {
    flex-basis: 40%;
    width: 40%; } }

@media (max-width: 992px) {
  .about__text {
    flex-basis: 100%;
    width: 100%;
    padding-right: 0px; }
  .about__text .text {
    padding-right: 20px;
    flex-basis: 70%; }
  .about__img {
    display: none; }
  .mobile-sertificate {
    flex-basis: 30%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
  .mobile-sertificate img {
    height: 250px;
    width: auto;
    max-width: none; } }

@media (max-width: 576px) {
  .about__text .text {
    flex-basis: 100%;
    padding-right: 0px; }
  .mobile-sertificate {
    flex-basis: 100%;
    justify-content: space-between; }
  .mobile-sertificate a {
    width: 48%;
    flex-basis: 48%;
    border: 1px solid #96151A; }
  .mobile-sertificate a img {
    max-width: 100%;
    height: auto; }
  .about__link {
    text-align: center;
    margin-top: 10px;
    width: 100%; } }

.section-c-interesting {
  background-image: url("/img/section-c-interesting.jpg");
  background-size: cover; }

.section-c-interesting .section__subtitle {
  color: #1E7FFF;
  margin-bottom: 30px;
  font-weight: 600;
  font-size: 18px; }

.c-interesting__inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.c-interesting__form-group {
  flex-basis: 49%;
  margin-bottom: 2%;
  display: flex;
  align-items: center; }

.c-interesting__form-group input[type='checkbox'] {
  flex-basis: 20px;
  width: 20px; }

.c-interesting__form-group .c-interesting__label {
  flex: 1;
  margin-bottom: 0px; }

@media (max-width: 992px) {
  .section-c-interesting .c-form__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px;
    padding-bottom: 0px; }
  .section-c-interesting .c-form__content .c-form__item {
    width: 32%;
    flex-basis: 32%; }
  .section-c-interesting .c-form__conf {
    justify-content: center; } }

@media (max-width: 576px) {
  .c-interesting__form-group {
    flex-basis: 100%;
    width: 100%; }
  .section-c-interesting .c-form__content .c-form__item {
    width: 100%;
    flex-basis: 100%; } }
