.section-advantages{
    position: relative;
    z-index: 2;
}
.section-advantages::before{
    background-image: url('../img/bg-section-advantages.png');
    //background-color: red;
    background-size: cover;
    height: 40%;
    width: 100%;
    content: '';
    position: absolute;
    top: 0px;
    z-index: 1;
}

.section-advantages::after{
    background-image: url('../img/bg-section-advantages2.png');
    background-size: cover;
    height: 60%;
    width: 100%;
    content: '';
    position: absolute;
    bottom: 0px;
    z-index: 1;
}


.section-advantages *{
    z-index: 9;
    position: relative;
}

.section-advantages .section__title{
    color: #FFF;
}

.section-advantages .section__text{
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
    color: #FFFFFF;
}


.advantages__wrapper{
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.advantages__item{
    flex-basis: 24%;
    margin-bottom: 50px;
    background-color: #FFF;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 30px;
}

.advantages__item::before{
    content: '';
    height: 3px;
    width: 50px;
    background-color: #1E7FFF;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
}

.advantages__icon{
    background: linear-gradient(180deg, #93C2FF 0%, #1E7FFF 80.89%);
    border-radius: 0px 0px 5px 5px;
    padding: 50px 0px 20px 0px;


    width: 100px;
    margin-top: -30px;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

}


.advantages__text {
    text-align: center;
    padding: 0px 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    color: #333;
    width: 100%;
}

.advantages-alert{
    display: flex;
    align-content: center;
    justify-content: space-between;

    background: #001951;
    border-radius: 5px;
    padding: 40px 20px;
    margin-bottom: -130px;
}

.advantages-alert__icon{
    flex-basis: 60px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.advantages-alert__text{
    flex: 1;
}
.advantages-alert__text p{
    color: #FFF;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 0px;
}

.advantages-alert__text p:not(:last-child){
    margin-bottom: 10px;
}




@media (max-width: 992px) {
    .advantages__wrapper{
        justify-content: center;
    }
    .advantages__item{
        flex-basis: 32%;
        width: 32%;
        margin-right: 1.33%;
    }
}

@media (max-width: 576px) {
    .advantages__wrapper{
        justify-content: space-between
    }
    .advantages__item{
        flex-basis: 49%;
        width: 49%;
        margin-right: 0px;
    }
    .advantages__icon{
        padding: 20px 0px 20px 0px;
    }
    .advantages__text{
        font-size: 14px;
        padding: 0px 10px;
    }

    .advantages-alert{
        flex-direction: column;
        padding: 15px;
    }
    .advantages-alert__icon{
        margin-right: 0px;
    }

    .advantages-alert__text p{
        font-size: 14px;
        text-align: center;
    }
}