.section-application{
    position: relative;
    padding-top: 130px;
}

.section-application *{
    z-index: 9;
    position: relative;
}

.section-application::before{
    content: '';
    position: absolute;
    bottom: 0px;
    z-index: 1;

    background-image: url('../img/bg-section-advantages.png');
    background-size: cover;
    height: 40%;
    width: 100%;
}

.application__wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.application__item{
    flex-basis: 49%;
    margin-bottom: 2%;

    position: relative;
}

.application__link{
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10;
}

.application__images{
    height: 190px;
}

.application__images img{
    object-fit: cover;
    width: 100%;
    height: 100%;
}
.application__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: #1E7FFF;
    padding: 0px 10px;
    margin-top: -5px;

    font-weight: bold;
    font-size: 18px;
    line-height: 1.2;
    color: #FFFFFF;

}
.application__icon{
    font-size: 33px;
    font-weight: 300;
}

.application__link:hover ~ .application__content,
.application__link:focus ~ .application__content{
    background-color: #AD0600;
}

@media (max-width: 576px) {
    .application__item{
        flex-basis: 100%;
    }
    .application__images{
        height: 120px;
    }
    .application__content{
        height: 45px;
        font-weight: normal;
        font-size: 14px;
    }
}