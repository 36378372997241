.section-promo {
    position: relative;
    background-size: cover;
    padding: 40px 0px;
    color: #fff;
}

.section-promo__wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.section-promo__content{
    flex-basis: 70%;
    min-height: 150px;
}

.section-promo__title{
    font-size: 50px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    line-height: 1.2;
}

.section-promo__desc{
    margin-top: 10px;
    display: inline-block;
    background-color: #0F1A34;
    color: #FFF;
    padding: 10px 20px;
    line-height: 1;;
    font-size: 30px;
    border-radius: 5px;

    margin-bottom: 30px;
}


.promo-utp__wrapper{

    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.promo-utp__item{
    flex-basis: 30%;
    margin-right: 3.33%;
    margin-bottom: 3.33%;

    display: flex;
    align-items: center;
}

.promo-utp__icon{
    background: linear-gradient(180deg, #5DA3FF 0%, #1E7FFF 100%);
    height: 55px;
    width: 55px;
    flex-basis: 55px;
    margin-right: 5px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.promo-utp__icon svg {
    overflow: hidden;
    vertical-align: middle;
}

.promo-utp__text{
    flex: 1;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
}


.section-promo__form{
    flex-basis: 30%;
}





@media (max-width: 1024px) {
    

}

@media (max-width: 992px) {
    .section-promo__wrapper{
        flex-wrap: wrap;
    }
    .section-promo__content, .section-promo__form{
        flex-basis: 100%;
        width: 100%;
    }
    .section-promo__wrapper .c-form{
        max-width: 100%;
    }
    .section-promo__wrapper .c-form__content{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 20px 10px;
        padding-bottom: 0px;
    }

    .section-promo__wrapper .c-form__content .c-form__item{
        width: 32%;
        flex-basis: 32%;
    }

    .section-promo__wrapper .c-form__conf {
        justify-content: center;
    }
}

@media (max-width: 576px) {
    .section-promo__title{
        font-size: 30px;
    }
    .section-promo__desc{
        padding: 10px;
        font-size: 20px;
    }
    .promo-utp__wrapper{
        justify-content: space-between;
    }
    .promo-utp__item{
        flex-basis: 49%;
        width: 49%;
        margin-right: 0px;
        margin-bottom: 10px;
    }
    .promo-utp__icon{
        width: 45px;
        height: 45px;
        flex-basis: 45px;
    }
    .promo-utp__text{
        font-weight: 300;
    }

    .section-promo__wrapper .c-form__content .c-form__item{
        width: 100%;
        flex-basis: 100%;
    }
}

