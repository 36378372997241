
body{
    overflow-x: hidden;
    font-family: 'Montserrat', sans-serif;
}

$accent-color: #0F1A34 !global; 
$accent-color2: #76d700 !global; 


@import "./components/form";
@import "./components/header-mobile";
@import "./components/header-clone";
@import "./components/header/header-2";

@import "./components/first-screen/block-2";
@import "./components/advantages/advantages-2";
@import "./components/application/application-1";

@import "./components/footer/footer-3";

.product__wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.flip-card{
    flex-basis: 32%;
    position: relative;
    margin-bottom: 20px;
    height: 240px;
}

.flip-card .front{
    position: relative;
    background: linear-gradient(270deg, #00123B 0%, rgba(0, 18, 59, 0.1) 95.53%);
    background-blend-mode: overlay, normal;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    height: 100%;
}
.flip-card .front .inner{
    transform: matrix(-1, 0, 0, 1, 0, 0);
    height: 100%;
    position: relative;
}
.flip-card .front .product__name{
    position: absolute;
    top: 20px;
    width: 70%;
    left: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #FFF;
}
.flip-card .front .product__images{
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    right: 0px;
}
.flip-card .front .product__btn{
    position: absolute;
    bottom: 0px;
    left: 0px;
    background-color: #1E7FFF;
    padding: 4px 10px;
    color: #FFF;
    cursor: pointer;
}


.flip-card .back{
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #ad0600cc;
}

.flip-card .back .back-cancel{
    height: 30px;
    width: 30px;
    background-color: #FFF;
    color: #96151A;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    cursor: pointer;
}

.flip-card .back .inner{
    padding: 20px;
    color: #FFF;

    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.flip-card .back .inner ul{
    padding-left: 20px;
}
.flip-card .back .inner p{
    font-size: 22px;
    font-weight: 600;
}

.flip-card .back .inner .product__link{
    background-color: #fff;
    padding: 10px 25px;
    border-radius: 5px;
    color: #AD0600;
    font-size: 18px;
    font-weight: 600;

    // height: 45px;
    margin-top: auto;
}

.flip-card .back .inner .product__link:hover,
.flip-card .back .inner .product__link:focus{
    opacity: .9;
}

.product-bottom__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-bottom__text{
    flex-basis: 64%;
    width: 64%;
}
.product-bottom__text p{
    margin-bottom: 0px;
}
.product-bottom__bnt{
    flex-basis: 32%;
}

.product-bottom__link{
    display: block;
    background: linear-gradient(180deg, #EA262E 0%, #96151A 100%);
    border-radius: 5px;
    color: #FFF;
    padding: 20px 0px;
    text-align: center;
}

.product-bottom__link:hover,
.product-bottom__link:focus{
    color: #FFF;
    background-color: #96151A;
    text-decoration: none;
}



@media (max-width: 992px) {
    .flip-card{
        flex-basis: 49%;
        width: 49%;
        height: 190px;
    }
}

@media (max-width: 576px) {
    .flip-card{
        flex-basis: 100%;
        width: 100%;
        height: 190px;
    }
    .flip-card .back .inner{
        padding: 10px;
    }

    .flip-card .back .inner *{
        font-size: 14px;
    }

    .product-bottom__content{
        flex-direction: column;
    }
    .product-bottom__text,
    .product-bottom__bnt{
        flex-basis: 100%;
        width: 100%;
    }

    .product-bottom__bnt{
        margin-top: 10px;
    }
}





.about__content {
    display: flex;
}

.about__text {
    flex-basis: 50%;
    padding-right: 20px;
}

.about__link{
    display: inline-block;
    background: linear-gradient(180deg, #EA262E 0%, #96151A 100%);
    border-radius: 5px;
    padding: 15px 40px;
    font-size: 18px;
    color: #FFF;
}

.about__link:hover,
.about__link:focus{
    background: #96151A;
    color: #FFF;
    text-decoration: none;
}

.about__img{
    flex-basis: 50%;
    position: relative;
}

.about__img::before{
    content: '';
    border: 1px solid #AD0600;
    width: 80%;
    height: 80%;
    bottom: 40px;
    left: 10%;
    position: absolute;
}

.about__images{
    width: 80%;
    position: relative;
    z-index: 3;
}

.about__sertificate {
    display: flex;
    align-items: center;
    margin-top: -110px;
    justify-content: flex-end;
    position: relative;
    z-index: 4;
}

.about__sertificate a:not(:last-child){
    margin-right: 20px;
}

.about__sertificate img{
    max-height: 200px;
    width: auto;
}

.about__text .d-flex{
    flex-wrap: wrap;
}
.about__text .text{
    flex-basis: 100%;
}

.mobile-sertificate{
    display: none;
}

@media (max-width: 1024px) {
    .about__text{
        flex-basis: 60%;
        width: 60%;
    }
    .about__text p{
        margin-bottom: 10px;
        font-size: 14px;
    }
    .about__img{
        flex-basis: 40%;
        width: 40%;
    }
}

@media (max-width: 992px) {
    .about__text{
        flex-basis: 100%;
        width: 100%;
        padding-right: 0px;
    }
    .about__text .text{
        padding-right: 20px;
        flex-basis: 70%;
    }
    .about__img{
        display: none;
    }
    .mobile-sertificate{
        flex-basis: 30%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .mobile-sertificate img{
        height: 250px;
        width: auto;
        max-width: none;
    }
}

@media (max-width: 576px) {
    .about__text .text{
        flex-basis: 100%;
        padding-right: 0px;
    }
    .mobile-sertificate{
        flex-basis: 100%;
        justify-content: space-between;
    }
    .mobile-sertificate a{
        width: 48%;
        flex-basis: 48%;
        border: 1px solid #96151A;
    }
    .mobile-sertificate a img{
        max-width: 100%;
        height: auto;
    }

    .about__link{
        text-align: center;
        margin-top: 10px;
        width: 100%;
    }
}






.section-c-interesting{
    background-image: url('/img/section-c-interesting.jpg');
    background-size: cover;
}
.section-c-interesting .section__subtitle{
    color: #1E7FFF;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 18px;
}


.c-interesting__inner{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.c-interesting__form-group{
    flex-basis: 49%;
    margin-bottom: 2%;
    display: flex;
    align-items: center;
}

.c-interesting__form-group input[type='checkbox']{
    flex-basis: 20px;
    width: 20px;
}
.c-interesting__form-group .c-interesting__label{
    flex: 1;
    margin-bottom: 0px;
}

@media (max-width: 992px) {
    .section-c-interesting .c-form__content{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 20px 10px;
        padding-bottom: 0px;
    }

    .section-c-interesting .c-form__content .c-form__item{
        width: 32%;
        flex-basis: 32%;
    }

    .section-c-interesting .c-form__conf {
        justify-content: center;
    }
}

@media (max-width: 576px) {
    .c-interesting__form-group{
        flex-basis: 100%;
        width: 100%;
    }
    .section-c-interesting .c-form__content .c-form__item{
        width: 100%;
        flex-basis: 100%;
    }

}