.header{
    padding: 10px 0px;
    background-color: #FFF;
}

.header__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
   
.header__wraper-logo{
    flex-basis: 15%;
    width: 15%;
}

.header__menu{
    flex-basis: 50%;
    width: 50%;
}

.header__list{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.header__link{
    font-size: 14px;
}

.header__link:hover,
.header__link:focus{
    color: #AD0600;
    text-decoration: none;
}

.header-contact__wrapper{
    flex-basis: 34%;
    width: 34%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header__contact{
    text-align: right;
    width: 50%;
    margin-right: 10px;
}

.header__phone{
    font-weight: 800;
}

.header__email{
    font-weight: 200;
    font-size: 14px;
    color: #777777;
}

.header__phone:hover,
.header__phone:focus{
    color: #AD0600;
    text-decoration: none;
}

.header__email:hover,
.header__email:focus{
    color: #AD0600;
    text-decoration: none;  
}

.header__btn{
    width: 50%;
}
.header-btn__link{
    padding: 10px 15px;
    font-weight: 600;
    color: #AD0600;
    border: 1px solid #AD0600;
    border-radius: 5px;
}

.header-btn__link:hover,
.header-btn__link:focus{
    background-color: #AD0600;
    color: #FFF;
    text-decoration: none;
}



@media (max-width: 1024px) {
    .header__wraper-logo{
        flex-basis: 11%;
        width: 11%;
    }
    .header__menu {
        flex-basis: 52%;
        width: 52%;
    }
    .header__link {
        font-size: 13px;
    }
    .header-contact__wrapper{
        flex-basis: 33%;
        width: 33%;
    }
    .header__contact{
        font-size: 14px;
    }
    .header-btn__link{
        padding: 5px;
        font-size: 14px;
    }
}

@media (max-width: 992px) {

}

@media (max-width: 576px) {

}
