.footer__top{
    background-color: #313B47;
    padding: 10px 0px;
}

.c-footer__wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.footer__logo{
    flex-basis: 200px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.footer__menu{
    flex-basis: 60%;
}

.footer__nav{
    margin: 0px;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer__nav-item:not(:last-child){
    margin-right: 10px;
}

.footer__nav-link{
    color: #FFF;
    font-size: 14px;
}

.footer__nav-link:hover,
.footer__nav-link:focus{
    color: #96151A;
    text-decoration: none;
}

.footer__contact{
    flex-basis: 15%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.footer__tel{
    font-size: 18px;
    font-weight: 600;
    color: #FFF;
}

.footer__tel:hover,
.footer__tel:focus{
    color: #96151A;
    text-decoration: none;
}

.footer__email{
    font-size: 14px;
    color: #FFF;
}

.footer__email:hover,
.footer__email:focus{
    color: #96151A;
    text-decoration: none;
}

.footer__bottom{
    padding: 10px 0px;
    background-color: #222222;
}

.bottom__text{
    flex-basis: 70%;
    width: 70%;
    color: #FFF;
    font-size: 13px;
}

.copy-wbooster{
    flex-basis: 30%;
    width: 30%;
    display: flex;
    justify-content: flex-end;
}

.wbooster{
    display: flex;
    align-content: center;
}
.wbooster__logo{
    flex-basis: 50px;
    margin-right: 5px;
   
    
}
.wbooster__text{
    flex: 1;
    font-size: 12px;
    color: #FFF;
    display: flex;
    align-items: center;
}
@media (max-width: 1024px) {
    .footer__contact{
        flex-basis: 25%;
        width: 25%;
    }
    .c-footer__wrapper .bottom__text{
        flex-basis: 62%;
    }
}

@media (max-width: 992px) {
    .footer__nav{
        display: none;
    }
    .footer__contact{
        flex-basis: 35%;
    }

    
}


@media (max-width: 576px){
    .c-footer__wrapper{
        flex-direction: column;
    }
    .footer__logo{
        flex-basis: 100%;
        width: 100%;
        justify-content: center;
        margin-bottom: 10px;
    }
    .footer__contact{
        flex-basis: 100%;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }
    .footer__tel,
    .footer__email{
        font-size: 16px;
        font-weight: normal;
    }

    .bottom__text{
        flex-basis: 100%;
        width: 100%;
        margin-bottom: 10px;
    }

    .copy-wbooster{
        flex-basis: 100%;
        width: 100%;
        justify-content: center;
    }
    
}